import Code from "../../../Additional/Code";

function IdAndSpliting() {
  return (
    <>
      <hr />
      <h2>ID & splitting</h2>
      <div className="divider"></div>
      <p>
        Every field should have a unique id. The id can be a string or integer.
        By that Id you have to identify the data which your user has filled. In
        the `values` object the id, which you have given before, is the key of
        the filled value. There’s only an exception for Select, Radio and
        Checkbox
      </p>
      <div className="divider-big"></div>
      <Code>
        {`Name
<Input id='1' type='text' />
Surname
<Input id='2' type='text' />`}
      </Code>
      <p>The values object will look like this</p>
      <Code>
        {`{
  1: "Jon",
  2: "Doe"
}`}
      </Code>
      <div className="divider-big"></div>
      <p>
        Great. So what if you want to keep these two values under one key. In
        that case you need to just use a dot. Yeah, simple `.` symbol. Just take
        a look at this example
      </p>
      <Code>
        {`Name
<Input id='1.name' type='text' />
Surname
<Input id='1.surname' type='text' />`}
      </Code>
      <p>The `values` object will look like this</p>
      <Code>
        {`{
  1: {
    "name": "Jon",
    "surname": "Doe"
  }
}`}
      </Code>
      <p>Isn’t this great ? :)</p>
      <div className="divider"></div>
      <p>And yes this works for as many levels as you want.</p>
      <Code>
        {`Name
<Input id='1.name.first' type='text' />
Surname 
<Input id='1.name.last' type='text' />
Address
<Input id='1.address' type='text' />`}
      </Code>
      <p>Result</p>
      <Code>
        {`{
  1: {
    "address": "New York",
    "name": {
      "first": "Jon",
      "last": "Doe"
    }
  }
}`}
      </Code>
    </>
  );
}

export default IdAndSpliting;

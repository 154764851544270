import React, { useState, useEffect } from "react";
import cx from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { ReactComponent as CopyIcon } from "../../../../Assets/Images/copy.svg";
import { ReactComponent as CopiedIcon } from "../../../../Assets/Images/copied.svg";

import "./style.scss";

export default function CopyButton({ value }) {
    const linesCount = value.length - value.replace(/\n/g, "").length + 1;
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(setCopied, 2000, false);
        }
    }, [copied]);

    return (
        <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <button
                className={cx("code-wrapper__copy-button", {
                    "multi-line-code-wrapper-button": linesCount > 1,
                    "single-line-code-wrapper-button": linesCount <= 1
                })}
            >
                {copied ? <CopiedIcon /> : <CopyIcon />}
            </button>
        </CopyToClipboard>
    );
}

import React from "react";

import Code from "../../../Additional/Code";

function HTMLValdiation() {
  return (
    <>
      <hr />
      <h2>HTML Validation</h2>
      <div className="divider"></div>
      <p>
        We also provide you with this great method to sync the BoomForm state
        with the actual HTML error handler.
      </p>
      <div className="divider"></div>
      <p>
        To sync the error state with the actual HTML error handler you need to
        pass one more parameter to the validation object, which is{" "}
        <b>HTMLValidate</b>
      </p>
      <div className="divider"></div>
      <p>
        As simple as you want. Just run the example, and you will see how easily
        all this works.
      </p>
      <Code>
        {`import React from 'react'
import { BoomForm, Input, Error } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Input
            id={1}
            validation={{
              HTMLValidate: true,
              required: { msg: 'This Field is required' }
            }}
          />
          <Error id='1' />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default HTMLValdiation;

import Code from "../../../Additional/Code";

function Radio() {
  return (
    <>
      <hr />
      <h2>Radio</h2>
      <div className="divider"></div>
      <p>Every other attribute will be given to the actual HTML input</p>
      <div className="divider"></div>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>string or number</td>
            <td>The Field Identifier</td>
          </tr>
          <tr>
            <td>initial</td>
            <td>string</td>
            <td>The default value</td>
          </tr>
          <tr>
            <td>name</td>
            <td>string or number</td>
            <td>By this parameter you can group checkboxes</td>
          </tr>
          <tr>
            <td>value</td>
            <td>any</td>
            <td>The value of this parameter you can get in values object</td>
          </tr>
          <tr>
            <td>validation</td>
            <td>object</td>
            <td>
              The validation object which will add a text passed using `msg` to
              the error object
            </td>
          </tr>
          <tr className="subrow">
            <td>HTMLValidate</td>
            <td>boolean</td>
            <td>For Letting HTML show the validation errors.</td>
          </tr>
          <tr className="subrow">
            <td>required</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
        </tbody>
      </table>
      <p>Example</p>
      <Code>
        {`import React from 'react'
import { BoomForm, Radio } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Radio
            id='1.yes'
            name='yes_or_no'
            value='Yes'
            validation={{
              HTMLValidate: true,
              required: { msg: 'Radio Field Is Required' }
            }}
          />
          <Radio id='1.no' name='yes_or_no' value='No' />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Radio;

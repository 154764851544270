import Note from "../../../Additional/Note/Note";
import Code from "../../../Additional/Code";

function Initials() {
  return (
    <>
      <hr />
      <h2>Initial Values</h2>
      <div className="divider"></div>
      <p>
        This is the one but not only way to give an initial value to the field.
        Giving initial value in this way is mostly done for conditional
        rendering.
      </p>
      <hr />
      <p>
        <b>initialValues</b> is not a required object which you can give to the
        Boomform component.
        <br />
        <br />
        The object's key is an id of the field.
        <br />
        The value should be a string except when the field is select. If the
        field is select you need to pass an object like in your select options
      </p>

      <div className="divider"></div>
      <Note>
        This way of giving initials is perfect when you are using conditional
        rendering. And you want to use the field value above your condition.
      </Note>
      <div className="divider"></div>

      <Code>
        {`import React, { useState } from 'react'
import { BoomForm, Input } from 'boomform'

const App = () => {
  const [smth, setSmth] = useState(false)

  return (
    <>
      <BoomForm
        smt={smth}
        initials={{
          'user.name': 'John',
          'user.surname': 'Doe'
        }}
      >
        {() => (
          <>
            <form>
              Name: <Input id='user.name' />
              <br />
              <div onClick={() => setSmth((p) => !p)}>Render Surname</div>
              <br />
              {smth && (
                <>
                  Surname: <Input id='user.surname' />
                </>
              )}
              <br />
            </form>
          </>
        )}
      </BoomForm>
    </>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Initials;

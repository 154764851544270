function WhyBoomForm() {
  return (
    <>
      <hr />
      <h2>Why BoomForm?</h2>
      <div className="divider"></div>
      <p>
        Using Boomform you don’t need to take care of data structure, fields'
        re-rendering etc… Boomform will do it for you. You just need to tell the
        program where your fields should be located, and Boomform will
        automatically create a state for them and follow your fields lifecycle.
      </p>
    </>
  );
}

export default WhyBoomForm;

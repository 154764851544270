function Actions() {
  return (
    <>
      <hr />
      <h2>Actions</h2>
      <div className="divider"></div>
      <p>All Fields have all the three actions described below.</p>
      <div className="divider"></div>
      <h3>onChange</h3>
      <div className="divider"></div>
      <p>
        It triggers every time when the user changes the value of the field. As
        an attribute you can get one object which keeps the following data
      </p>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
          <tr>
            <td>value</td>
            <td>The last value which the user has changed</td>
          </tr>
        </tbody>
      </table>
      <hr />
      <h3>onBlur</h3>
      <div className="divider"></div>
      <p>
        It triggers every time when the user clicks outside of the field. As an
        attribute, you can get one object which keeps the following data
      </p>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Actions;

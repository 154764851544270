import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from "./Components/Navigation/Index";
import Content from "./Components/Content"; 

import "./styles.scss";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="navigation">
          <Navigation />
        </div>
        <div className="content">
          <Content />
        </div>
      </div>
    </Router>
  );
}

export default App;

import React from "react";

function Data({ submenus }) {
  return (
    <>
      <h1>Data Usage</h1>
      <hr />
      <p>
        You have a couple of ways to get the data and manipulate it however you
        want. Below you will find all the ways.
      </p>
      {submenus.map((submenu, index) => {
        const { Component, id } = submenu;
        return (
          <section key={index} id={id}>
            <Component />
          </section>
        );
      })}
    </>
  );
}

export default Data;

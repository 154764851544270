import React from "react";
import Menu from "./Menu";

import "./styles.scss";

function Navigation() {
  return (
    <>
      <div className="logo"></div>
      <div className="menu">
        <Menu />
      </div>
    </>
  );
}

export default Navigation;

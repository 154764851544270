import React from "react";
import { Switch, Route } from "react-router-dom";
import ROUTES from "../../routes";

import "./styles.scss";

function Content() {
  return (
    <Switch>
      {ROUTES.map((route) => {
        const { path, Component, submenus } = route;
        return (
          <Route key={path} exact path={path}>
            <div className="wrapper">
              <Component submenus={submenus} />
            </div>
          </Route>
        );
      })}
    </Switch>
  );
}

export default Content;

import React from "react";
import Code from "../../../Additional/Code";
import { HashLink } from "react-router-hash-link";
import Note from "../../../Additional/Note/Note";

function CustomDataUsage() {
  return (
    <>
      <hr />
      <h2>Custom Data Usage</h2>
      <div className="divider"></div>
      <p>
        When you use boomform and want to use it to its full potential, we
        present you with the following functionality
      </p>
      <div className="divider"></div>
      <h3>
        You can bind functions to window and use them anywhere in your project
      </h3>
      <div className="divider"></div>
      <Code>
        {`import React from 'react'
import { BoomForm, Input} from 'boomform'

const App = () => {
  return (
    <>
      <BoomForm>
        {(data) => {
          console.log(data)
          window.handleChnage = data.handleChange
          window.handleReset = data.handleReset
          window.updateId = data.updateId
          window.declareFields = data.declareFields
          window.handleBlur = data.handleBlur
          window.handleDelete = data.handleDele
          return(
          <>
            <form>
              Name: <Input id='user.name' />
              <br />
              Surname: <Input id='user.surname' />
              <br />
              <Input id='thing' />
            </form>
          </>
        )}}
      </BoomForm>
    </>
  )
}
export default App`}
      </Code>
      <div className="divider-big"></div>
      <p>Example using handleChange and handleReset</p>
      <div>
        <Code>
          {`
              
import React from 'react'
import { BoomForm, Input} from 'boomform'

const App = () => {


  return (
    <>
      <BoomForm>
        {({handleChange,handleReset}) => {
          return(
          <>
            <form>
              Name: <Input id='user.name' />
              <br />
              Surname: <Input id='user.surname' />
              <br />
              <Input id='thing' />
            </form>
       
            <button
                  onClick={() =>
                   handleChnage({ id: 'user.name', value: 'Joe' })
                  }
                >
                  Button handleChange
            </button>
            
            <button
                  onClick={() =>
                   handleReset()
                  }
                >
                  Button RESET FORM
            </button
    
          </>
        )}}
      </BoomForm>
    </>
  )
}

export default App
              
              
              `}
        </Code>
      </div>
      <table>
        <tbody>
          <tr>
            <td>handleChange</td>
            <td>
              This function should be used for updating data in the values
              object.
            </td>
          </tr>

          <tr className="subrow">
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
          <tr className="subrow">
            <td>value</td>
            <td>The value which you want to keep in the state</td>
          </tr>
          <tr className="subrow">
            <td>event</td>
            <td>React synthetic event</td>
          </tr>
          <tr className="subrow">
            <td>ref</td>
            <td>React Ref</td>
          </tr>

          <tr>
            <td>handleBlur</td>
            <td>
              This function should be used for updating touched in values
              object.
            </td>
          </tr>
          <tr className="subrow">
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
          <tr className="subrow">
            <td>value</td>
            <td>The value which you want to keep in the state</td>
          </tr>
          <tr>
            <td>declareField</td>
            <td>
              Creates a field and gets an object that has an id, type, and
              initial value
            </td>
          </tr>
          <tr>
            <td>updateId</td>
            <td>
              If you put change the old id with a new id you have updateId
              function, it should be called and given an object with new and old
              ids
            </td>
          </tr>
          <tr>
            <td>declareFields</td>
            <td>
              Creates a Fields and gets an object that has an id, type, and
              initial value
            </td>
          </tr>
          <tr>
            <td>window.__current_form_state</td>
            <td>You have current form state in window</td>
          </tr>
          <tr>
            <td>handleDelete</td>
            <td>
              <Note>
                You have <b>id and spliting</b>
                <HashLink
                  to="/start#id-and-splitting"
                  style={{ marginLeft: "10px" }}
                >
                  here
                </HashLink>
              </Note>
              if you need to delete a field, or deep object in a field
            </td>
          </tr>
        </tbody>
      </table>
      <Code>
        {`
<Input id='1.user.name' type='text' />               
                `}
      </Code>
      The "values" object will look like this
      <Code>
        {`
      {
        1: {
            user: {
                name: "Joe"
            }
        }
    }`}
      </Code>
      The "values" object will look like this
      <Code>
        {`               
handleDelete("1.user.name")
{
  1: {
      user: {

      }
  }
}`}
      </Code>
      The "values" object will look like this
      <Code>
        {`
handleDelete("1.user")

{
  1: {

  }
}
             `}
      </Code>
      <hr />
      <p>Example using window</p>
      <Code>
        {`import React from 'react'
import { BoomForm,Input} from 'boomform'

const App = () => {


  return (
    <>
      <BoomForm>
        {(data) => {
          console.log(data)
          window._handleChnage = data.handleChange
          window.handleReset = data.handleReset
          window.updateId = data.updateId
          window.declareFields = data.declareFields
          window.declareField = data.declareField
          window.handleBlur = data.handleBlur
          window.handleDelete = data.handleDelete
          return(
          <>
            <form>
              Name: <Input id='user.name' />
              <br />
              Surname: <Input id='user.surname' />
              <br />
              <Input id='thing' />
            </form>
            <div className='button-group'>
            <button
                  onClick={() =>
                    window._handleChnage({ id: 'user.name', value: 'Joe' })
                  }
                >
                  Button handleChange
                </button>
            <button
              onClick={()=>window.handleReset()}
            >
              Button Reset Form
            </button>
            <button
            onClick={()=>window.handleDelete('user.name')}
          >
            Delete field
          </button>
          </div>
    
          </>
        )}}
      </BoomForm>
    </>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default CustomDataUsage;

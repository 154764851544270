import React from "react";

function Start({ submenus }) {
  return (
    <>
      <h1>Getting Started</h1>
      <hr />
      <p>
        Hey and welcome. You have started the development with the most advanced
        and easy form Builder
      </p>
      {submenus.map((submenu, index) => {
        const { Component, id } = submenu;
        return (
          <section key={index} id={id}>
            <Component />
          </section>
        );
      })}
    </>
  );
}

export default Start;

import { ReactComponent as RunIcon } from "../../../../Assets/Images/run.svg";

import "./style.scss";

export default function RunButton({ link }) {
    return (
        <a href={link} target={"_blank"} rel="noreferrer" className="code-wrapper__run-button">
            <RunIcon />
        </a>
    );
}

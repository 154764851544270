import Code from "../../../Additional/Code";

function Custom() {
  return (
    <>
      <hr />
      <h2>Custom</h2>
      <div className="divider"></div>
      <p>
        Using custom you can create anything you want while keeping the state in
        boomform store
      </p>
      <div className="divider"></div>
      <p>
        The Custom component takes only id for declaring field in Errors,
        Touched etc. and validation for validating data
      </p>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>string or number</td>
            <td>The Field Identifier</td>
          </tr>
        </tbody>
      </table>
      <p>
        You can take all the following parameters inside the custom component
      </p>
      <table>
        <tbody>
          <tr>
            <td>handleChange</td>
            <td>
              This function should be used for updating data in the values
              object.
            </td>
          </tr>
          <tr className="subrow">
            <td>e</td>
            <td>The default event which is taken from actual HTML</td>
          </tr>
          <tr className="subrow">
            <td>field</td>
            <td>All parameters you have passed to the changed field</td>
          </tr>
          <tr className="subrow">
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
          <tr className="subrow">
            <td>value</td>
            <td>The value which you want to keep in the state</td>
          </tr>
          <tr>
            <td>handleBlur</td>
            <td>
              This function should be used for updating touched in values
              object.
            </td>
          </tr>
          <tr className="subrow">
            <td>e</td>
            <td>The default event which is taken from actual HTML</td>
          </tr>
          <tr className="subrow">
            <td>field</td>
            <td>All parameters you have passed to the changed field</td>
          </tr>
          <tr className="subrow">
            <td>id</td>
            <td>The unique identification of your field</td>
          </tr>
          <tr className="subrow">
            <td>value</td>
            <td>The value which you want to keep in the state</td>
          </tr>
          <tr>
            <td>id</td>
            <td>The id of your field</td>
          </tr>
          <tr>
            <td>value</td>
            <td>The current value of your field</td>
          </tr>
          <tr>
            <td>fields</td>
            <td>Object with all fields you have declared</td>
          </tr>
          <tr>
            <td>errors</td>
            <td>Object with all your errors</td>
          </tr>
          <tr>
            <td>touched</td>
            <td>Object with all your touched or untouched fields</td>
          </tr>
          <tr>
            <td>values</td>
            <td>Object with all your values</td>
          </tr>
        </tbody>
      </table>

      <p>Example</p>
      <Code>
        {`import React from 'react'
import { BoomForm, Custom } from 'boomform'

const App = () => {
  const handleButtonClick = (handleChange, e, id, status) => {
    handleChange({
      value: status,
      id,
      e,
      field: { type: 'custom', id }
    })
  }

  return (
    <BoomForm>
      {() => (
        <Custom id='1'>
          {({ handleChange, value, id }) => {
            return (
              <div className='button-group'>
                <button
                  onClick={(e) => handleButtonClick(handleChange, e, id, 0)}
                >
                  Button 1 {value === 0 && 'active'}
                </button>
                <button
                  onClick={(e) => handleButtonClick(handleChange, e, id, 1)}
                >
                  Button 2 {value === 1 && 'active'}
                </button>
                <button
                  onClick={(e) => handleButtonClick(handleChange, e, id, 2)}
                >
                  Button 3 {value === 2 && 'active'}
                </button>
              </div>
            )
          }}
        </Custom>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Custom;

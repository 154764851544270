import Note from "../../../Additional/Note/Note";
import Code from "../../../Additional/Code";

function Select() {
  return (
    <>
      <hr />
      <h2>Select</h2>
      <div className="divider"></div>
      <p>Every other attribute will be given to the actual HTML input</p>
      <div className="divider-big"></div>
      <Note>
        To create a placeholder you just need to set the option key
        `placeholder`. If the placeholder has been chosen and the field is
        required the error will be shown
      </Note>

      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>string or number</td>
            <td>The Field Identifier</td>
          </tr>
          <tr>
            <td>initial</td>
            <td>string</td>
            <td>The default value</td>
          </tr>
          <tr>
            <td>options</td>
            <td>array</td>
            <td>
              By this object you will pass the options of select{" "}
              <i>
                (any other parameter will be available in the values object)
              </i>
            </td>
          </tr>
          <tr className="subrow">
            <td>key</td>
            <td>integer</td>
            <td>identifier of option</td>
          </tr>
          <tr className="subrow">
            <td>value </td>
            <td>integer</td>
            <td>text which will be shown</td>
          </tr>

          <tr>
            <td>validation</td>
            <td>object</td>
            <td>
              The validation object which will add a text passed using `msg` to
              the error object
            </td>
          </tr>
          <tr className="subrow">
            <td>HTMLValidate</td>
            <td>boolean</td>
            <td>For Letting HTML show the validation errors.</td>
          </tr>
          <tr className="subrow">
            <td>required</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
        </tbody>
      </table>
      <p>Example</p>
      <Code>
        {`import React from 'react'
import { BoomForm, Select } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Select
            id='1'
            initial='1'
            options={[
              { key: 'placeholder', value: ' -- Choice One -- ' },
              { key: '1', value: 'Today' },
              { key: '2', value: 'Tomorrow' },
              { key: '3', value: 'Next Week' }
            ]}
          />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Select;

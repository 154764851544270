import Code from "../../../Additional/Code";

function Installation() {
  return (
    <>
      <hr />
      <h2>Installation</h2>
      <div className="divider"></div>
      <p>
        For installation, you just need to copy the following code into the
        console of your project root directory.
      </p>
      <Code>{"npm i boomform"}</Code>
      <p>or</p>
      <Code>{"yarn add boomform"}</Code>
      <p>Nothing extraordinary. Just like with every other npm package</p>
    </>
  );
}

export default Installation;

import React from "react";

import "./styles.scss";

function Note({ children }) {
  return (
    <div className="note">
      <div className="note__icon"></div>
      <div className="note__text">{children}</div>
    </div>
  );
}

export default Note;

import { HashLink } from "react-router-hash-link";
import Note from "./../../../Additional/Note/Note";

import Code from "../../../Additional/Code";

function useField() {
  return (
    <>
      <hr />
      <h2>useField hook.</h2>
      <div className="divider"></div>
      <p>
        useField is the simplest and easiest way to get the data you want.
        <br />
        <br />
        The best thing is that you can make{" "}
        <b>re-render your component when only one field has been changed</b>
      </p>
      <div className="divider-big"></div>
      <p>useField is just an ordinary hook so the usage is as well.</p>
      <hr />

      <p>useField as a parameter takes an array with field ids.</p>
      <div className="divider"></div>
      <Code>
        {`import { useField } from boomform;

-- 
  const fields = useField(["user"]);
--`}
      </Code>
      <div className="divider"></div>
      <p>
        That's it, in the `fields` variable you have all the needed information.
        And when the `user` or nested on `user` field changes your component
        will re-render
      </p>
      <hr />
      <p>Let's take a look at exactly what data you are getting.</p>
      <div className="divider"></div>
      <Note>
        In the first render all the objects will be empty and the values will be
        null.
      </Note>
      <div className="divider"></div>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>The id of the field which change caused re-rendering</td>
          </tr>
          <tr>
            <td>ids</td>
            <td>
              The array of ids which can cause re-render of your component
            </td>
          </tr>
          <tr>
            <td>value</td>
            <td>The last value which the user has typed</td>
          </tr>
          <tr>
            <td>touched</td>
            <td>Is the field which change caused rerender touched</td>
          </tr>
          <tr>
            <td>neededValues</td>
            <td>
              Object of field values which ids have been given to useField hook
              <div className="divider"></div>
              <Note>
                in <b>neededValues</b> you will get the data nested as described
                in <HashLink to="/start#id-and-splitting">here</HashLink>
              </Note>
            </td>
          </tr>
          <tr>
            <td>newValues</td>
            <td>Object of all fields with new values</td>
          </tr>
          <tr>
            <td>newErrors</td>
            <td>Object of all errors</td>
          </tr>
          <tr>
            <td>prevState</td>
            <td>The previews state </td>
          </tr>
        </tbody>
      </table>
      <hr />
      <div className="divider"></div>
      <p>Check the example and see how easy is this</p>
      <div className="divider"></div>
      <Code>
        {`import React from 'react'
import { BoomForm, Input, useField } from 'boomform'

const SomeComponent = () => {
  const neededState = useField(['user'])
  console.log(neededState)
  return 'Check your console'
}

const App = () => {
  return (
    <>
      <BoomForm>
        {() => (
          <>
            <form>
              Name: <Input id='user.name' />
              <br />
              Surname: <Input id='user.surname' />
              <br />
              <p>I'm not re-rendering SomeComponent</p>
              <br />
              <Input id='thing' />
            </form>
            <SomeComponent />
          </>
        )}
      </BoomForm>
    </>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default useField;

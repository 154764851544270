import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import ROUTES from "../../../routes";

import "./styles.scss";

// Need To Move To Helpers
const getCurrentPage = (path) => {
  const exact = path.substring(1);
  if (exact === "") return ROUTES[0].id;
  return exact;
};

// Need To Move To Helpers
const getCurrentMenuIdBySubMenu = (subId) => {
  for (let i = 0; i < ROUTES.length; i++)
    for (let j = 0; j < ROUTES[i].submenus.length; j++) {
      if (ROUTES[i].submenus[j].id === subId) return ROUTES[i].id;
    }
};

function Menu() {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const currentSubMenuId = hash.slice(1);

  const currentPathId = getCurrentPage(pathname);

  const handleMenuClick = (id) => history.push(`/${id}`);
  const handleSubMenuClick = (id) => {
    const currentMenuId = getCurrentMenuIdBySubMenu(id);
    if (currentMenuId !== currentPathId) {
      history.push(currentMenuId);
      // this is just for changing hash AFTER react router. So browser can handle default scroll
      // if you will find better way I'll kis your chakat
      setTimeout(() => (window.location.hash = id));
    } else window.location.hash = id;
  };

  return (
    <>
      {ROUTES.map((menu, index) => {
        const { name, id, submenus } = menu;
        return (
          <div key={index}>
            <div
              className={classNames("menu__item", {
                menu__item_active: currentPathId === id,
              })}
              onClick={() => handleMenuClick(id)}
            >
              {name}
            </div>
            <div className="submenu">
              {submenus.map((submenu, index) => {
                const { name: subName, id: subId } = submenu;
                return (
                  <div
                    key={index}
                    className={classNames("submenu__item", {
                      submenu__item_active: currentSubMenuId === subId,
                    })}
                    onClick={() => handleSubMenuClick(subId)}
                  >
                    {subName}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default Menu;

import { HashLink } from "react-router-hash-link";

import Code from "../../../Additional/Code";

function FirstUse() {
  return (
    <>
      <hr />
      <h2>First Use</h2>
      <div className="divider"></div>
      <p>
        Firstly you need to export `BoomForm` and `Input` components from the
        boomform.
      </p>
      <div className="divider"></div>
      <table>
        <tbody>
          <tr>
            <td>BoomForm</td>
            <td>
              BoomForm is literally your state keeper. You can’t use form data
              outside of this component (About data usage read{" "}
              <HashLink to="/start#data-usage">here</HashLink>). And all
              components, imported from BoomForm, you can use only inside of
              this component.
            </td>
          </tr>
          <tr>
            <td>Input</td>
            <td>
              Input is a simple field (more about this and other fields read{" "}
              <HashLink to="/fields#input">here</HashLink>)
            </td>
          </tr>
        </tbody>
      </table>
      <div className="divider"></div>
      <p>
        There you go. Just write the BoomForm component and pass as a child
        whatever content you want with the Input component.
      </p>
      <div className="divider-big"></div>
      <Code>
        {`import React from 'react'
import { BoomForm, Input } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Input id='1' type='text' />
        </>
      )}
    </BoomForm>
  )
}

export default App`}
      </Code>
      <div className="divider-big"></div>
      <p>
        If you have done everything as it is described above, everything should
        be fine. So that’s it. You have created your first form with BoomForm.
        Congratulations! Keep going and read more in the next sections, and you
        will learn how to create more complicated forms as easy as this one
      </p>
    </>
  );
}

export default FirstUse;

import Start from "./Components/Content/Start/Index";
import WhyBoomForm from "./Components/Content/Start/submenus/WhyBoomForm";
import Installation from "./Components/Content/Start/submenus/Installation";
import FirstUse from "./Components/Content/Start/submenus/FirstUse";
import IdAndSpliting from "./Components/Content/Start/submenus/IdAndSpliting";
import Initials from "./Components/Content/Start/submenus/Initials";
import Errors from "./Components/Content/Start/submenus/Errors";
import HTMLValdiation from "./Components/Content/Start/submenus/HTMLValidation";

import Data from "./Components/Content/Data/Index";
import UseField from "./Components/Content/Data/submenus/useField";
import Context from "./Components/Content/Data/submenus/Context";

import Fields from "./Components/Content/Fields";
import Actions from "./Components/Content/Fields/submenus/Actions";
import Input from "./Components/Content/Fields/submenus/Input";
import Textarea from "./Components/Content/Fields/submenus/Textarea";
import File from "./Components/Content/Fields/submenus/File";
import Checkbox from "./Components/Content/Fields/submenus/Checkbox";
import Radio from "./Components/Content/Fields/submenus/Radio";
import Select from "./Components/Content/Fields/submenus/Select";
import Custom from "./Components/Content/Fields/submenus/Custom";
import CustomDataUsage from "./Components/Content/Data/submenus/CustomDataUsage";

const ROUTES = [
  {
    id: "start",
    name: "Getting Started",
    path: ["/", "/start", "/start/*"],
    Component: Start,
    submenus: [
      {
        id: "why-boomform",
        name: "Why BoomForm ?",
        path: "why-boomform",
        Component: WhyBoomForm,
      },
      {
        id: "installation",
        name: "Installation",
        path: "installation",
        Component: Installation,
      },
      {
        id: "first-use",
        name: "First Use",
        path: "first-use",
        Component: FirstUse,
      },
      {
        id: "id-and-splitting",
        name: "Id And Splitting",
        path: "id-and-splitting",
        Component: IdAndSpliting,
      },
      {
        id: "error",
        name: "Error",
        path: "error",
        Component: Errors,
      },
      {
        id: "initials",
        name: "Initials",
        path: "initials",
        Component: Initials,
      },
      {
        id: "htmlvalidation",
        name: "HTML Validation",
        path: "HTML-validation",
        Component: HTMLValdiation,
      },
    ],
  },
  {
    id: "data",
    name: "Data",
    path: ["/data", "/data/*"],
    Component: Data,
    submenus: [
      {
        id: "context",
        name: "Context",
        path: "context",
        Component: Context,
      },
      {
        id: "useField",
        name: "useField",
        path: "useField",
        Component: UseField,
      },{
        id:"customDataUsage",
        name:"Custom Data Usage",
        path:"customDataUsage",
        Component:CustomDataUsage
      }
    ],
  },
  {
    id: "fields",
    name: "Fields",
    path: ["/fields", "/fields/*"],
    Component: Fields,
    submenus: [
      {
        id: "actions",
        name: "Actions",
        path: "actions",
        Component: Actions,
      },
      {
        id: "input",
        name: "Input",
        path: "input",
        Component: Input,
      },
      {
        id: "textarea",
        name: "Textarea",
        path: "textarea",
        Component: Textarea,
      },
      {
        id: "file",
        name: "File",
        path: "file",
        Component: File,
      },
      {
        id: "checkbox",
        name: "Checkbox",
        path: "checkbox",
        Component: Checkbox,
      },
      {
        id: "radio",
        name: "Radio",
        path: "radio",
        Component: Radio,
      },
      {
        id: "select",
        name: "Select",
        path: "select",
        Component: Select,
      },
      {
        id: "custom",
        name: "Custom",
        path: "Custom",
        Component: Custom,
      },
    ],
  },
];

export default ROUTES;

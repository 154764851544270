import { HashLink } from "react-router-hash-link";

import Code from "../../../Additional/Code";

function Context() {
  return (
    <>
      <hr />
      <h2>Data Usage</h2>
      <div className="divider"></div>
      <p>
        So here is the most interesting part and the main reason why use
        BoomForm. Just follow the steps described below and you will get the
        whole state.
      </p>
      <div className="divider"></div>
      <ol>
        <li>
          First of all, for more readable code let's create one more component
          and call him `Store`
        </li>
        <li>In the `Store` component import `useContext` from react</li>
        <li>In the `Store` component import `Context` from `BoomForm`</li>
        <li>
          Declare a new variable named `state` which is equal to `useContext`
        </li>
        <li>
          Pass `Context`, which is exported from `BoomForm`, as a parameter to
          `useContext`
        </li>
        <li>Just console the `state` variable</li>
        <li>
          Get back to the main component and import there `State` component
        </li>
        <li>Pass the `State` component as a child to `BoomForm` component</li>
      </ol>
      <div className="divider"></div>
      <p>
        That’s it. Just open the console and type something in your input and
        check the results
      </p>

      <div className="divider-big"></div>

      <p>
        <i>App.js</i>
      </p>
      <Code>
        {`import React from 'react'
import { BoomForm, Input } from 'boomform'
import State from './State'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Input id='1' type='text' />
          <State />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>

      <div className="divider-big"></div>

      <p>
        <i>State.js</i>
      </p>
      <Code>
        {`import { useContext } from 'react'
import { Context } from 'boomform'
 
const State = () => {
  const store= useContext(Context)
 
  console.log(store)
 
  return null
}
 
export default State`}
      </Code>
      <div className="divider"></div>
      <p>
        If you have done everything right you will get an object in your console
        with two main keys `state` and `actions`
      </p>
      <div className="divider"></div>
      <p>
        About `actions` you can read{" "}
        <HashLink to="/fields#actions">here</HashLink>
      </p>
      <div className="divider"></div>
      <p>
        For now let's take a look at the state object. P.S. All your data is
        stored in the `state`
      </p>
      <table>
        <tbody>
          <tr>
            <td>fields</td>
            <td>
              Fields is an array where fields are kept as structured nested
              objects. There you can see what fields you have, what parameters
              you have given to your fields, etc...
            </td>
          </tr>
          <tr>
            <td>values</td>
            <td>
              The `values` is the main object where all data, filled by your
              user or from initial values, is stored.
            </td>
          </tr>
          <tr>
            <td>touched</td>
            <td>
              in the `touched` object you can find which field is touched. The
              object keys are the id-s of your fields and they are all false by
              default, So the first time when your user changes the field, the
              value of the changed field will be true.
            </td>
          </tr>
          <tr>
            <td>errors</td>
            <td>
              in errors you can find the fields which haven’t passed the
              validation rules. you have also the messages which you have set in
              the validation rules
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default Context;

import React from "react";

function Fields({ submenus }) {
  return (
    <>
      <h1>Fields</h1>
      <hr />
      <p>
        Fields is a component which returns simple html elements already
        connected with the store.
      </p>
      {submenus.map((submenu, index) => {
        const { Component, id } = submenu;
        return (
          <section key={index} id={id}>
            <Component />
          </section>
        );
      })}
    </>
  );
}

export default Fields;

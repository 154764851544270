import Note from "../../../Additional/Note/Note";
import Code from "../../../Additional/Code";

function Errors() {
  return (
    <>
      <hr />
      <h2>Errors</h2>
      <div className="divider"></div>
      <p>
        As you know from the Data Usage page in your state you have two objects
        <b> errors</b> And <b>touched</b>
      </p>
      <div className="divider"></div>
      <p>
        In the <b>errors</b> object you can see all errors which exist until the
        field is filed right
      </p>
      <div className="divider"></div>
      <p>
        In the <b>touched</b> object you can see which field is touched or not
        if the value is false that means the field is not touched yet
        <br />
      </p>
      <div className="divider"></div>
      <Note>
        If the field has an initial value it will be touched by default
      </Note>
      <div className="divider-big"></div>
      <p>
        So if you want to show an error to your user, you just need to get those
        two objects from the state, and if there is an error in the error object
        and the field is touched show some error to your user
      </p>
      <div className="divider"></div>
      <p>
        Or just import the `Error` component from `BoomForm` and pass the field
        id as a prop which error you want to be shown
      </p>

      <Code>
        {`import React from 'react'
import { BoomForm, Input, Error } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Input
            id={1}
            validation={{
              required: { msg: 'This Field is required' }
            }}
          />
          <Error id='1' />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Errors;

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import CopyButton from "./CopyButton";
import RunButton from "./RunButton";

import "./style.scss";

function Code({ link, children }) {
    return (
        <div className={"code-wrapper"}>
            <CopyButton value={children} />

            {link !== null && <RunButton link={link} />}

            <SyntaxHighlighter language="jsx" style={a11yDark} className="code" wrapLongLines>
                {children}
            </SyntaxHighlighter>
        </div>
    );
}

Code.defaultProps = {
    link: null
};

export default Code;

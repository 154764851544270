import Code from "../../../Additional/Code";

function Input() {
  return (
    <>
      <hr />
      <h2>Input</h2>
      <div className="divider"></div>
      <p>Every other attribute will be given to the actual HTML input</p>
      <div className="divider"></div>
      <table>
        <tbody>
          <tr>
            <td>id</td>
            <td>string or number</td>
            <td>The Field Identifier</td>
          </tr>
          <tr>
            <td>type</td>
            <td>enum ( text, number, password, phone, email )</td>
            <td>
              The HTML input type. All of them should be text input. This is for
              identifying the inputted text type
            </td>
          </tr>
          <tr>
            <td>initial</td>
            <td>string</td>
            <td>The default value</td>
          </tr>
          <tr>
            <td>validation</td>
            <td>object</td>
            <td>
              The validation object which will add a text passed using `msg` to
              the error object
            </td>
          </tr>
          <tr className="subrow">
            <td>HTMLValidate</td>
            <td>boolean</td>
            <td>For letting HTML show the validation errors.</td>
          </tr>
          <tr className="subrow">
            <td>required</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
          <tr className="subrow">
            <td>min</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object{" "}
              <br />
              <b>type :</b> The type can be length, word or number <br />
              <b>value :</b> The quantity of the type <br />
            </td>
          </tr>
          <tr className="subrow">
            <td>max</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object{" "}
              <br />
              <b>type :</b> The type can be length, word or number <br />
              <b>value :</b> The quantity of the type <br />
            </td>
          </tr>
          <tr className="subrow">
            <td>email</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
          <tr className="subrow">
            <td>phone</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
          <tr className="subrow">
            <td>url</td>
            <td>object</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
            </td>
          </tr>
          <tr className="subrow">
            <td>regEx</td>
            <td>object / array</td>
            <td>
              <b>msg :</b> The message which will appear in the errors object
              <br />
              <b>value :</b> (Allows custom validation using a regular
              expression.) The value is a "string" of regEx , which passes to
              the "new RegExp" constructor.
              <br /> <br /> If you wish to employ multiple regular expression
              validations, you can utilize an array containing objects with
              'msg' (error message) and 'value' properties.
            </td>
          </tr>
        </tbody>
      </table>
      <p>Example</p>
      <Code>
        {`import React from 'react'
import { BoomForm, Input } from 'boomform'

const App = () => {
  return (
    <BoomForm>
      {() => (
        <>
          Simple Input:
          <Input
            id='1'
            type='text'
            placeholder='Type here ...'
            validation={{
              HTMLValidateL: true,
              required: { msg: 'This field is required' },
              min: {
                msg: 'Type more than 5 characters',
                value: 5,
                type: 'length'
              },
              regEx: {
                msg:'Please type only uppercase letters',
                value: "/[A-Z]/"
                }
            }}
          />
        </>
      )}
    </BoomForm>
  )
}

export default App
`}
      </Code>
    </>
  );
}

export default Input;
